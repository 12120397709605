// websocket实时通讯
import service from '../request'
const baseUrl = window.globalUrl.HOME_API;
// 发送消息
export const SendMessage= (params) => {
  return service({
    url: `${baseUrl}/trade-website/online/send`,
    method: 'post',
    data: params,
  })
}
//查看我的流转审批记录列表
export const selectMyApprovalRecord = (params) => {
  return service({
    url: `${baseUrl}/trade-website/cqjy/selectMyApprovalRecord`,
    method: 'get',
    params:params,
  })
}
// 资源资产流转审批记录列表查询接口
export const selectApprovalRecord = (params) => {
  return service({
    url: `${baseUrl}/trade-website/cqjy/selectApprovalRecord`,
    method: 'get',
    params:params,
  })
}