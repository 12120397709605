<template>
  <el-dialog
  title="资源资产流转审批"
  :visible.sync="dialogVisible"
  width="80%"
  append-to-body
  :before-close="handleClose">
  <div class="transact">
    <div class="Tips" style="display: flex;">
     <div> 退回原因：</div><div style="color:red"> 张三流转土地，提交金额和合同照片不一致</div>
    </div>

    <div class="screen">
      <el-form :inline="true">
        <el-form-item label="提交时间：">
          <el-date-picker
            style="width: 150px;"
            v-model="form.startTime"
            placeholder="选择开始日期"
            value-format="yyyy-MM-dd"
            clearable
          >
          </el-date-picker>
          至
          <el-date-picker
            style="width: 150px;"
            v-model="form.endTime"
            placeholder="选择结束日期"
            align="right"

            value-format="yyyy-MM-dd"
            clearable
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="流出方">
          <el-input
            style="width: 190px"
            v-model="form.startPeople"
            placeholder="请输入"
          >
          </el-input>
        </el-form-item>

        <el-form-item label="交易类型">
          <el-select style="width: 190px" v-model="form.taskType">
            <el-option
              v-for="item in typeOptions"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>

        <div class="button" style="display: inline-block; margin: 0">
          <el-form-item>
            <el-button class="query-btn" type="primary" @click="findBtn"
              >查询</el-button
            >
            <el-button class="query-btn" type="primary" @click="resubmit"
              >重新提交</el-button
            >

          </el-form-item>
        </div>
      </el-form>

    </div>

    <div class="table-content">
      <table-page
         ref="approvalTable"
        :data="tableData"
        :defaultSelectList="defaultSelectList"
        :columns="columns"
        :currentPage="query.pnum"
        :pageSize="query.psize"
        :total="total"
        :loading="loading"
        :border="true"
        :isShow="isShow"
        @sizeChange="handleSizeChange"
        @currentChange="handleCurrentChange"
        @selectionChange="handleSelection"
        @handSelectAll="handSelectAll"
        @handSelect="handSelect"
      >
        <template slot="zcmj" slot-scope="scope">
          <span>{{ scope.row.zcmj }}亩</span>
        </template>

        <!-- scope.row.applyId -->
        <template slot="lzlx" slot-scope="scope">
          <span>{{ scope.row.zcmj }}万元</span>
        </template>
      </table-page>
    </div>
    <!-- 第二层弹窗 -->
        <el-dialog
      title="如下资源资产交易记录缺少合同归档文件，请上传完成后在提交审批"
      :visible.sync="dialogVisible2"
      width="80%"
      append-to-body
      :before-close="handleClose2">
      <TablePageTwo
      :data="tableData"
      :columns="columns2"
      >
      <template slot="operation" slot-scope="scope">
        <el-button type="primary" @click="HeadersTong">编辑合同归档文件</el-button>

      </template>
      </TablePageTwo>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
  <!-- 第三层弹窗 -->
  <el-dialog
  title="四川省达州市达川区"
  :visible.sync="dialogVisible3"

  append-to-body
  :before-close="handleClose3">

     <!-- 图片上传 -->
     <el-upload class="upload-demo" ref="AvatarUpload" action=""
     :accept="'.jpg, .JPG, .png, .PNG, .jpeg, .JPEG,'" list-type="picture-card" :auto-upload="false"
     :file-list="fileList" :on-change="upLoadImg" multiple>
     <i slot="default" class="el-icon-plus"></i>
     <div slot="file" slot-scope="{ file }">
       <el-image ref="preview" style="height: 115px"
       class="el-upload-list__item-thumbnail"
       :src="file.url"
       :preview-src-list="innerTableList"
       alt=""
       :initial-index="BigImgIndex"
        />
       <span>{{ file.name }}</span>
       <span class="el-upload-list__item-actions">
         <span v-if="!file.isShow " class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
           <i class="el-icon-zoom-in"></i>
         </span>
         <span  class="el-upload-list__item-delete" @click="handleDownload(file)">
           <i class="el-icon-download"></i>
         </span>
         <span  class="el-upload-list__item-delete" @click="handleRemove(file)">
           <i class="el-icon-delete"></i>
         </span>
       </span>
     </div>
    </el-upload>

  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
</el-dialog>

</el-dialog>
</template>

<script>
import { WriteProps,resubmitProps } from "../tableColumn";
import TablePageTwo from "@/components/table/CallForBids-page";
import TablePage from "@/components/table/Approve-page";
export default {
  data() {
    return {

        form:{
          startTime:'',
          endTime:'',
          startPeople:'',
          taskType:'',
          processNumber:'',
        },
        typeOptions: [
        {
          label: "全部",
          value: 'null',
        },
        {
          label: "转让",
          value: "1",
        },
        {
          label: "出租",
          value: "2",
        },
        {
          label: "入股",
          value: "2",
        },
      ],
        query:{
          current: 1,
           size: 10,
        },
          tableData: [
        {
          xmbh:'237288743',
          xmmc:'四川省/达州市/宣汉县/大竹镇/大竹村/52亩耕地出租',
          gpjg:'2910/07/16',
          zcmj:'1728亩',
          lznx:'91',
          lzlx:'2172181',
          fbsj:'达川区/堡子镇/龙族村/集体经济联合社'
        },
        {
          xmbh:'237288743',
          xmmc:'四川省/达州市/宣汉县/大竹镇/大竹村/52亩耕地出租',
          gpjg:'2019/07/16',
          zcmj:'1728亩',
          lznx:'91',
          lzlx:'2172181',
          fbsj:'达川区/堡子镇/龙族村/集体经济联合社'
        },
        {
          xmbh:'237288743',
          xmmc:'四川省/达州市/宣汉县/大竹镇/大竹村/52亩耕地出租',
          gpjg:'2910/07/16',
          zcmj:'1728亩',
          lznx:'91',
          lzlx:'2172181',
          fbsj:'达川区/堡子镇/龙族村/集体经济联合社'
        },
        {
          xmbh:'237288743',
          xmmc:'四川省/达州市/宣汉县/大竹镇/大竹村/52亩耕地出租',
          gpjg:'2910/07/16',
          zcmj:'1728亩',
          lznx:'91',
          lzlx:'2172181',
          fbsj:'达川区/堡子镇/龙族村/集体经济联合社'
        }
      ],
        isShow:false,//不展示分页，展示统计数据
        defaultSelectList:[],
        columns:WriteProps,
        columns2:resubmitProps,
        keyForm: 1,
        total: 0,
        dialogVisible: true,
        dialogVisible2:false,
        dialogVisible3:false,
        loading: false,
        fileList:[]

    };
  },
  components:{
    TablePage,
    TablePageTwo
  },
  methods:{
    handleClose(){
      this.$emit("closeDialog4", false);
    },
    handleClose2(){
      this.dialogVisible2=false
    },
    handleClose3(){
      this.dialogVisible3=false
    },
    handleCurrentChange(page) {
                this.query.pnum = page;
                // this.ApplicationRecordList();
    },
       //分页总页数切换
    handleSizeChange(size) {
                this.query = {
                  pnum: 1,
                  psize: size,
                };
                // this.ApplicationRecordList();
    },
    handleSelection(selects) {
                console.log(selects);
    },  // 全选或者反选
    handSelectAll(selection) {
      this.defaultSelectList = selection;
      // console.log(selection, "全选");
    },
    //多选
    handSelect(selection) {
      this.defaultSelectList = selection;
      // console.log(selection, "多选");
    },
    findBtn(){

    },
    clear(){

    },
    handSelect(){

    },
    resubmit(){
      this.dialogVisible2=true
    },
    HeadersTong(){
      this.dialogVisible3=true
    },
        //删除
        handleRemove(file, fileList) {
      //可用版本1
      // console.log(file, fileList);
      const index = this.fileList.findIndex((item) => item.uid === file.uid);
      this.fileList.splice(index, 1);
    },
    //放大
    handlePictureCardPreview(file) {
      // console.log(file,'查看file')
      this.BigImgIndex= this.fileList.findIndex(item => item.uid === file.uid);
      // console.log( this.fileList,'查看fileList')
      let abc = [];
      this.fileList.forEach((its) => {
        if (!its.isShow) {
          abc.push(its.url);
        }
      });
      this.innerTableList = abc;
      this.$nextTick(() => {
        this.$refs.preview.clickHandler();
      });
    },
  }
};
</script>
<style lang="less" scoped>
.screen{
  padding-top:20px;
}
</style>>

