//流转审批
import service from "../request/index";
const baseUrl = window.globalUrl.HOME_API;

// 新版本-获取土地类型
export const selectNoApproval = (data) => {
  return service({
    url: `${baseUrl}/trade-website/cqjy/selectNoApproval`,
    method: "get",
    params: data,
  });
};
// 提交审批
export const insertApprovar = (params) => {
  console.log(params,'查看传到js里了嘛')
  return service({
    url: `${baseUrl}/trade-website/cqjy/insertApprovar`,
    method: "post",
    data: params,
  });
};
