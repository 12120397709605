<template>
   <!-- 第一层弹窗 -->
  <el-dialog
  title="资源资产流转审批"
  append-to-body
  :visible.sync="dialogVisible3"
  width="80%"
  :before-close="handleClose3">
  <div class="Approve">

<!-- <h3>资源资产流转审批</h3> -->
<!-- 列表搜索条件，已封装 -->
<el-form :inline="true">
  <el-form-item label="提交时间：">
    <el-date-picker
      v-model="form.startTime"
      placeholder="选择开始日期"
      value-format="yyyy-MM-dd"
      clearable
    >
    </el-date-picker>
    至
    <el-date-picker
      v-model="form.endTime"
      placeholder="选择结束日期"
      align="right"
      value-format="yyyy-MM-dd"
      clearable
    >
    </el-date-picker>
  </el-form-item>
  <el-form-item label="发起人">
    <el-input
      style="width: 190px"
      v-model="form.startPeople"
      placeholder="请输入"
    >
    </el-input>
  </el-form-item>

  <el-form-item label="任务类型">
    <el-select style="width: 190px" v-model="form.taskType">
      <el-option
        v-for="item in typeOptions"
        :key="item.label"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
  </el-form-item>
  <el-form-item label="审批号">
    <el-input
      style="width: 190px"
      v-model="form.processNumber"
      placeholder="请输入"
    >
    </el-input>
  </el-form-item>
  <div class="button" style="display: inline-block; margin: 0">
    <el-form-item>
      <el-button class="query-btn" type="primary" @click="list"
        >查询</el-button
      >
      <el-button class="query-btn" type="primary" @click="clear"
        >清空</el-button
      >
      <el-button class="query-btn" type="primary" @click="query"
        >下载年度流转审批表</el-button
      >
      <el-button class="query-btn" type="primary" @click="clear"
        >下载本年度补贴公告公示</el-button
      >
    </el-form-item>
  </div>
</el-form>
  <div class="tools">
      <el-button type="success"  @click="submitApproval()">提交审批</el-button>
  </div>
  <div class="table-content">
    <table-page
       ref="approvalTable"
      :isShow="isShow"
      :data="approvalVoList"
      :defaultSelectList="defaultSelectList"
      :columns="columns"
      :currentPage="query.pnum"
      :pageSize="query.psize"
      :total="total"
      :loading="loading"
      :border="true"
      @sizeChange="handleSizeChange"
      @currentChange="handleCurrentChange"
      @selectionChange="handleSelection"
      @handSelectAll="handSelectAll"
      @handSelect="handSelect"
    >
      <template slot="flowAreaMu" slot-scope="scope">
        <span>{{ scope.row.flowAreaMu }}亩</span>
      </template>

      <!-- scope.row.applyId -->
      <template slot="lzlx" slot-scope="scope">
        <span>{{ scope.row.zcmj }}万元</span>
      </template>
    </table-page>
  </div>
  <!-- 第二层弹窗  z-index="1000"-->
  <el-dialog    append-to-body width="80%" title="如下资源资产交易记录缺少合同归档文件，请上传完成后在提交审批" :visible.sync="outerVisible">
    <TablePageTwo
    :data="tableData2"
    :columns="columns2"
    :currentPage="query2.pnum2"
    :pageSize="query2.psize2"
    :total="total2"
    :loading="loading2"
    :border="true">
    <template slot="zcmj" slot-scope="scope">
      <span>{{ scope.row.zcmj }}亩</span>
    </template>

    <!-- scope.row.applyId -->
    <template slot="lzlx" slot-scope="scope">
      <span>{{ scope.row.zcmj }}万元</span>
    </template>
    <template slot="operation" slot-scope="scope">
      <el-button type="text" size="small" @click="innerVisible=true">上传合同照片</el-button>
    </template>

    </TablePageTwo>
<!-- 第三层弹窗  z-index="9999999"-->
    <el-dialog
    append-to-body
      title="合同照片上传"
      :visible.sync="innerVisible"
     >
      <!-- 切换图片上传类型 -->
        <!-- <div class="tabs">
          <div  v-for="(item,index) in tabsList" :key="index" :class="index==tabsInex? 'itemCls' :''" @click="tabsClick(index)">
            {{item.title}}
          </div>

        </div> -->

      <!-- 图片上传 -->
     <el-upload class="upload-demo" ref="AvatarUpload" action=""
     :accept="'.jpg, .JPG, .png, .PNG, .jpeg, .JPEG,'" list-type="picture-card" :auto-upload="false"
     :file-list="fileList" :on-change="upLoadImg" multiple>
     <i slot="default" class="el-icon-plus"></i>
     <div slot="file" slot-scope="{ file }">
       <el-image ref="preview" style="height: 115px"
       class="el-upload-list__item-thumbnail"
       :src="file.url"
       :preview-src-list="innerTableList"
       alt=""
       :initial-index="BigImgIndex"
        />
       <span>{{ file.name }}</span>
       <span class="el-upload-list__item-actions">
         <span v-if="!file.isShow " class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
           <i class="el-icon-zoom-in"></i>
         </span>
         <span  class="el-upload-list__item-delete" @click="handleDownload(file)">
           <i class="el-icon-download"></i>
         </span>
         <span  class="el-upload-list__item-delete" @click="handleRemove(file)">
           <i class="el-icon-delete"></i>
         </span>
       </span>
     </div>
   </el-upload>


    </el-dialog>
    <div slot="footer" class="dialog-footer">
      <el-button @click="innerVisible = false">取消关闭</el-button>
      <el-button type="primary" @click="innerVisible = false">提交审批</el-button>
    </div>
  </el-dialog>

</div>
</el-dialog>
</template>
<script>
const Query = [
  {
    name: "title",
    label: "提交时间",
    componentType: "input",
    inputType: "",
    placeholder: "请选择",
    width: "160px",
    dataOptions: [],
  },
  {
    name: "departName",
    label: "状态：",
    componentType: "input",
    placeholder: "请选择",
    width: "140px",
    inputType: "",
    dataOptions: [],
  },

  {
    name: "status",
    label: "交易类型",
    componentType: "select",
    placeholder: "请选择",
    width: "180px",
    dataOptions: [
      {
        name: "全部",
        value: 0,
      },
      {
        name: "转让",
        value: -1,
      },
      {
        name: "出租",
        value: 1,
      },
      {
        name: "入股",
        value: 2,
      },
    ],
  },
  {
    name: "departName",
    label: "产权类型：",
    componentType: "input",
    placeholder: "请选择",
    width: "140px",
    inputType: "",
    dataOptions: [],
  },

];
import { uploadFile } from "@/api/fileApi";
import { selectNoApproval,insertApprovar } from "@/api/FlowApproval.js";
import { spColumn,sqProps  } from "../tableColumn";
import FormQuery from "@/components/form/form-search";
import TablePage from "@/components/table/Approve-page";
import TablePageTwo from '@/components/table/CallForBids-page'
export default {
	components: {

	},
	data() {
		return {
      dialogVisible3: true,
      Query: Query,
      approvalVoList:[],//发起审批的列表所有数据的list
      approvalNowYearsList:[],//发起审批的列表本年度的list
      defaultSelectList:[],//选中的列表
      isShow:false,
      loading: false,
      loading2: false,
      columns: spColumn,
      columns2: sqProps,
      keyForm: 1,
      total: 0,
      total2: 0,
      outerVisible: false,
      innerVisible: false,
      baseUrl: window.globalUrl.BASE_API,
      tableData2:[
        {
          xmbh:'237288743',
          xmmc:'四川省/达州市/宣汉县/大竹镇/大竹村/52亩耕地出租',
          gpjg:'2910/07/16',
          zcmj:'1728亩',
          lznx:'91',
          lzlx:'2172181',
          fbsj:'达川区/堡子镇/龙族村/集体经济联合社'
        },
        {
          xmbh:'237288743',
          xmmc:'四川省/达州市/宣汉县/大竹镇/大竹村/52亩耕地出租',
          gpjg:'2019/07/16',
          zcmj:'1728亩',
          lznx:'91',
          lzlx:'2172181',
          fbsj:'达川区/堡子镇/龙族村/集体经济联合社'
        },
        {
          xmbh:'237288743',
          xmmc:'四川省/达州市/宣汉县/大竹镇/大竹村/52亩耕地出租',
          gpjg:'2910/07/16',
          zcmj:'1728亩',
          lznx:'91',
          lzlx:'2172181',
          fbsj:'达川区/堡子镇/龙族村/集体经济联合社'
        },
        {
          xmbh:'237288743',
          xmmc:'四川省/达州市/宣汉县/大竹镇/大竹村/52亩耕地出租',
          gpjg:'2910/07/16',
          zcmj:'1728亩',
          lznx:'91',
          lzlx:'2172181',
          fbsj:'达川区/堡子镇/龙族村/集体经济联合社'
        }
      ],
      tabsInex:0,
      contractPhoto:[],  // 存储合同照片
      otherPhoto:[],  // 存储其他材料
      innerTableList: [],
      BigImgIndex:0,
      form:{
        startTime:'',
        endTime:'',
        startPeople:''
      },
      query: {
        current: 1,
        size: 10,
      },
      query2: {
        pnum2: 1,
        psize2: 10,
      },
      fileList: [],

      tabsList:[
        {
         title:'合同照片'
        },{
          title:'其他类型'
        }
      ],
      typeOptions: [
        {
          label: "全部",
          value: 'null',
        },
        {
          label: "资源资产备案",
          value: "1",
        },
        {
          label: "补贴申请",
          value: "2",
        },
      ],

    }

	},
  components: {
    FormQuery,
    TablePage,
    TablePageTwo
  },
	created() {
    this.selectNoApproval()
	},
  methods: {
    handleClose3() {
      this.$emit("closeDialog3", false);
    }, // 点击搜索按钮
    async search(SearchItem) {
                let params = {
                  // userId: this.identityObj.identityId,
                  ...SearchItem,
                  ...this.query,
                };
                console.log(params);
                let res = await ApplicationRecordList(params);
                this.tableData = res.data;
                this.total = res.dataNum;
                // this.query.pnum = 1;
                // // 组装条件
                // this.query = {
                //   ...this.query,
                //   ...params,
                //   ...{ cropCode: params.cropCode.join(",") },
                // };
    }, // 搜索条件重置按钮
    async reset() {
                this.query = {
                  pnum: 1,
                  psize: 10,
                };
                let params = {
                  // userId: this.identityObj.identityId,
                  ...this.query,
                };
                console.log(params);
                let res = await ApplicationRecordList(params);
                this.tableData = res.data;
                this.total = res.dataNum;
    },
       //分页单页切换
    handleCurrentChange(page) {
                this.query.pnum = page;
                this.ApplicationRecordList();
    },
       //分页总页数切换
    handleSizeChange(size) {
                this.query = {
                  pnum: 1,
                  psize: size,
                };
                this.ApplicationRecordList();
    },
    handleSelection(selects) {
                console.log(selects);
    },  // 全选或者反选
    handSelectAll(selection) {
      this.defaultSelectList = selection;
      // console.log(selection, "全选");
    },
    //多选
    handSelect(selection) {
      this.defaultSelectList = selection;
      // console.log(selection, "多选");
    },

    // 打开外层弹窗
    submitApproval(){
      this.outerVisible = true;
      console.log(this.defaultSelectList,'获取选中的值')
      // outerVisible = true
      // 查询上传的数据是都上传过图片

    },

      //删除
      handleRemove(file, fileList) {
      //可用版本1
      // console.log(file, fileList);
      const index = this.fileList.findIndex((item) => item.uid === file.uid);
      this.fileList.splice(index, 1);
    },
    //放大
    handlePictureCardPreview(file) {
      // console.log(file,'查看file')
      this.BigImgIndex= this.fileList.findIndex(item => item.uid === file.uid);
      // console.log( this.fileList,'查看fileList')
      let abc = [];
      this.fileList.forEach((its) => {
        if (!its.isShow) {
          abc.push(its.url);
        }
      });
      this.innerTableList = abc;
      this.$nextTick(() => {
        this.$refs.preview.clickHandler();
      });
    },
     // 改变回显的样式
    getViewUrl1(arr) {
      arr.forEach(async (item) => {
        item.name = item.FilesName;
        item.url = this.baseUrl + "admin/file/get?ossFilePath=" + item.imgUrl;
        item.url2 = item.imgUrl;
      });
      this.fileList = arr;
    },
       // 切换样式
      //  tabsClick(index){
      // this.tabsInex=index
      // this.fileList=[]
      // if(index==0){
      //  this.fileList=this.contractPhoto
      //   console.log('this.contractPhoto', this.contractPhoto)
      // }else{
      //   this.fileList=this.otherPhoto
      //   console.log(' this.otherPhoto',  this.otherPhoto)
      // }
    // },

    async upLoadImg(file, fileList) {
        let res = await this.upload(file, fileList);
    },
      // 上传
   async upload(file, fileList) {
      let param = new FormData();
      param.append("file", file.raw);
      param.append("fileList", fileList);
      let res = await uploadFile(param);
      file.url2 = res.data.url;

      if(this.tabsInex==0){
        this.contractPhoto.push(fileList[fileList.length-1])
        this.fileList = this.contractPhoto
        console.log('this.contractPhoto', this.contractPhoto)

      }else{
        this.otherPhoto.push(fileList[fileList.length-1])
        this.fileList = this.otherPhoto
        console.log('this.otherPhoto',this.otherPhoto)
      }
      // console.log(fileList,'fileList')
      // this.fileList = fileList;
    },
    //发起流转审批列表
    async selectNoApproval(){
      let params={
        current:this.query.current,
        size:this.query.size,
      }
      let res= await selectNoApproval(params)
      if(res.code==0)
      {
        this.approvalVoList=res.data.approvalVoList.records,
        this.defaultSelectList=res.data.approvalNowYearsList

      }
      console.log('查看获取到的提交列表', this.approvalList)
    },
    //提交审批
  async  submitApproval(){
    let xmbhList=[]
    let xmbsmList=[]
      this.defaultSelectList.forEach((item,index)=>{
        xmbhList.push(item.xmbh)
      })

      this.defaultSelectList.forEach((item,index)=>{
        xmbsmList.push(item.xmbsm)
      })
      console.log(xmbsmList,'项目标识码合集')
      console.log(xmbhList,'项目编号合集')
    let params={
      xmbhList:xmbhList,
      xmbsmList:xmbsmList,
    }
    let  res= await insertApprovar(params)
    console.log(res)
    },



},



}
</script>



<style lang="less" scoped>
.Approve{
  width:100%;
  height: 100%;

}
.tabs{
  width: 100%;
  height:100%;
  display: flex;
  margin-bottom: 20px;
  justify-content: space-around;
  .itemCls{
    display: block;
    background: #ccc;
  }
}
</style>
